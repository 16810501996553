import React from 'react'

import ReviewFormElement from '../elements/ReviewFormElement'
import StarsFormElement from '../elements/StarsFormElement'
import { FormButtonsElement } from 'components/shared/Forms/FormCommonButtons'
import { UserBlurb } from 'components/shared/Users/UserBlurb'

export default class ItemReviewStep extends React.Component {
  render() {
    const { rental, initialData } = this.props
    const { item_review, item_rating } = initialData
    let { item_name, item_img } = rental || {}
    let user = {
      firstName: item_name,
      picture: item_img,
    }
    return (
      <div>
        <h3>Leave a review for the {item_name}</h3>
        <UserBlurb user={user} />
        <StarsFormElement
          name="item_rating"
          rating={item_rating}
          onChangeStars={this.props.onChangeStars}
        />
        <ReviewFormElement
          name="item_review"
          itemReview={item_review}
          onChange={this.props.onChange}
        />
        <FormButtonsElement
          {...this.props}
          nextText="Save & Continue"
          disabled={item_rating == null || item_review == ''}
        />
      </div>
    )
  }
}
