/**
 * Review Form Element
 */

import React from 'react'

export default class ReviewFormElement extends React.Component {
  render() {
    const { review, name } = this.props
    return (
      <div className="rental-form-group">
        <fieldset className="fieldset fieldset-with-label">
          <label>Describe the experience</label>
          <textarea
            className="text-input"
            type="text"
            name={name}
            maxLength="1600"
            rows="6"
            required
            defaultValue={review}
            onChange={this.props.onChange}
          />
        </fieldset>
      </div>
    )
  }
}
