import React from 'react'

import ReviewFormElement from '../elements/ReviewFormElement'
import StarsFormElement from '../elements/StarsFormElement'
import { FormButtonsElement } from 'components/shared/Forms/FormCommonButtons'
import { UserBlurb } from 'components/shared/Users/UserBlurb'

export default class UserReviewStep extends React.Component {
  render() {
    const { rental, reviewerType, initialData } = this.props
    let { user_review, user_rating } = initialData
    let user = {
      firstName: '',
      pictures: '',
    }
    if (reviewerType == 'renter') {
      user.firstName = rental.lender_name
      user.picture = rental.lender_img
    } else if (reviewerType == 'lender') {
      user.firstName = rental.renter_name
      user.picture = rental.renter_img
    }
    return (
      <div>
        <h3>Leave a review for {user.firstName}</h3>

        <UserBlurb user={user} />

        <StarsFormElement
          name="user_rating"
          rating={user_rating}
          onChangeStars={this.props.onChangeStars}
        />
        <ReviewFormElement
          name="user_review"
          userReview={user_review}
          onChange={this.props.onChange}
        />
        <FormButtonsElement
          {...this.props}
          nextText="Save & Continue"
          disabled={user_rating == null || user_review == ''}
        />
      </div>
    )
  }
}
