/**
 * Stars Form Element
 */

import React from 'react'
import ReactStars from 'react-stars'

export default class StarsFormElement extends React.Component {
  render() {
    const { rating, name } = this.props
    return (
      <div className="rental-form-group">
        <fieldset className="fieldset fieldset-with-label">
          <label>Give a rating</label>
          <ReactStars
            count={5}
            value={rating}
            name={name}
            required
            onChange={newRating => this.props.onChangeStars(newRating, name)}
            size={30}
            color1={'#E0E0E0'}
            color2={'#56578d'}
          />
        </fieldset>
      </div>
    )
  }
}
