import React from 'react'

export default class ConfirmationStep extends React.Component {
  render() {
    return (
      <div style={{ minHeight: 350 }}>
        <h3>Thank you for leaving a review</h3>
        <p>
          Your response has been recorded. We hope you had a great experience!
        </p>
      </div>
    )
  }
}
